<template>
  <div class="selector">
  <el-popover
    placement="bottom-start"
    width="228"
    v-model="visible">
    <div style="max-height: 300px; overflow: scroll;">
      <el-tree :data="optionList" current-node-key="label" :props="defaultProps" highlight-current @node-click="handleNodeClick"></el-tree>
    </div>
    <el-input slot="reference" ref="input" :value="defaultValue" clearable @clear="handleClear" placeholder="请选择分类" />
  </el-popover>
  </div>
</template>
<script>
import { getMedicalTree } from '@/api/spareParts';
export default {
  name: 'MEDICAL_TREE_SELECTOR',
  props: {
    name: {
      type: String,
    },
  },
  data () {
    return {
      optionList: [],
      defaultValue: this.name,
      defaultProps: {
        children: 'childList',
        label: 'name',
      },
      visible: false,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
  },
  methods: {
    getList () {
      getMedicalTree()
        .then((res) => {
          this.optionList = res?.body || [];
        });
    },
    handleNodeClick (v) {
      if (v) {
        this.$emit('input', v.name);
        this.$emit('update:name', v.name);
        this.$emit('change', v);
      }
    },
    handleClear () {
      const v = {
        id: '',
        name: '',
      };

      this.$emit('input', v.name);
      this.$emit('update:name', v.name);
      this.$emit('change', v);
    },
  },
  created () {
    this.getList();
  },
};
</script>
