<template>
 <div>
    <el-select
      popper-class="multislelector"
      class="w-100"
      v-model="sparePartDevices"
      value-key="id"
      multiple
      filterable
      clearable
      remote
      :disabled="disabled"
      placeholder="请选择设备"
      :remote-method="getList"
      @visible-change="handleVisibleChange"
      @change="handleChange"
      :loading="loading">
      <div class="option">
        <div>
            <el-option
                v-for="item in optionList"
                :key="item.id"
                :label="item.name"
                :value="item">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.spec }}</span>
            </el-option>
        </div>
      </div>
      <div class="pagin">
        <el-pagination
          small
          @current-change="handleCurrentChange"
          :page-size="params.pageSize"
          layout="prev, pager,next,total"
          :total="total"
          style="text-align: end"
        ></el-pagination>
      </div>
    </el-select>
 </div>
</template>
<script>
import DeviceApi from '@/api/device';
export default {
  name: 'Multi_Device_SELECTOR',
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    devices: function (val) {
      this.sparePartDevices = val;
      this.optionList = val;
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      sparePartDevices: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      inputValue: '',
      total: 0,
    };
  },
  methods: {
    getList (name) {
      this.loading = true;
      this.inputValue = name;
      DeviceApi.searchDevice({
        name,
        enable: true,
        ...this.params,
      }).then(res => {
        this.optionList = res.body?.list || [];
        this.total = res.body?.total || 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    handleChange (opts) {
      this.$emit('change', opts);
    },
    handleVisibleChange (visible) {
      if (visible) {
        this.getList();
      }
    },
    handleCurrentChange (val) {
      this.params.pageNum = val;
      this.getList(this.inputValue);
    },
  },
};

</script>

<style lang="scss">
  // 组件名定义，避免污染全局select
  .multislelector {
    .el-select-dropdown__wrap {
      max-height: 300px !important;
      .option {
        max-height: 240px;
        overflow-y: auto;
      }
    }
  }
</style>
